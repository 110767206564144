<template>
  <v-container v-if="renderComponent" class="py-0 py-sm-8 px-0 px-sm-4">
    <div style="oveflow-x:auto;min-width:1160px!important;">
      <v-row no-gutters>
        <v-col cols="12" class="pb-6">
          <div class="d-flex fill-width align-center justify-space-between">
            <v-btn fab small color="primary" @click.prevent="NavCalendar('prev')">
              <v-icon>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-menu
              ref="filterMenu"
              v-model="filterMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  hide-details
                  depressed
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $localDT(focus + '-01', 'monthnamelocal') }}
                </v-btn>
              </template>
              <v-date-picker
                v-model="focus"
                type="month"
                :month-format="(m) => {
                  return $localDT(m, 'monthnamelocal')
                }"
                no-title
                scrollable
                color="primary"
                @input="filterMenu = false"
              />
            </v-menu>
            <v-btn fab small color="primary" @click.prevent="NavCalendar('next')">
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12">
          <v-sheet height="2750">
            <v-calendar
              ref="calendarSchedule"
              type="month"
              :today="new Date()"
              :value="focus + '-01'"
              :short-months="false"
              :short-weekdays="false"
              :weekdays="[1, 2, 3, 4, 5, 6, 0]"
              color="primary"
              @change="updateRange"
            >
              <template v-slot:day="{ past, date }">
                <v-row
                  no-gutters
                >
                  <v-col
                    v-for="(st, iSt) in $store.state.schedule_type"
                    :key="'schedule-' + date + '-' + iSt"
                    cols="12"
                    width="100%"
                    class="pa-2"
                  >
                    <v-card v-if="parseInt(ParseSchedule(date, st.value).id)" class="pa-2 elevation-1">
                      <v-img
                        :src="$getImage(ParseSchedule(date, st.value).galleries)"
                        :src-lazy="$getImage(ParseSchedule(date, st.value).galleries)"
                        :aspect-ratio="1"
                        class="grey lighten-4 rounded-l-lg rounded-br-lg rounded-tr-lg"
                      />
                      <v-chip x-small style="position: absolute;top:20px;left:20px;">
                        {{ st.name }}
                      </v-chip>
                      <v-btn
                        :color="past ? 'grey' : 'red'"
                        dark
                        x-small
                        fab
                        style="position: absolute;top:10px;right:10px;"
                        @click.prevent="past ? false : showEvent($event, { date, type: st.value, data: Object.assign({}, ParseSchedule(date, st.value)) })"
                      >
                        <v-icon small>
                          {{ past ? 'mdi-lock' : 'mdi-close' }}
                        </v-icon>
                      </v-btn>
                      <div x-small style="position: absolute;bottom:20px;left:20px;right:20px;font-size:12px;font-weight:bold;">
                        {{ ParseSchedule(date, st.value).product_name }}
                      </div>
                    </v-card>
                    <v-card v-else class="pa-2 elevation-1">
                      <v-img
                        :aspect-ratio="1"
                        class="grey lighten-4 rounded-l-lg rounded-br-lg rounded-tr-lg"
                      />
                      <v-chip x-small style="position: absolute;top:20px;left:20px;">
                        {{ st.name }}
                      </v-chip>
                      <v-btn
                        :color="past ? 'grey' : 'blue'"
                        dark
                        x-small
                        fab
                        style="position: absolute;top:10px;right:10px;"
                        @click.prevent="past ? false : showEvent($event, { date, type: st.value, data: Object.assign({}, ParseSchedule(date, st.value)) })"
                      >
                        <v-icon small>
                          {{ past ? 'mdi-lock' : 'mdi-plus' }}
                        </v-icon>
                      </v-btn>
                      <!-- <div x-small style="position: absolute;bottom:20px;left:20px;right:20px;font-size:12px;font-weight:bold;">
                        No Name
                      </div> -->
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-calendar>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
    <v-menu
      v-model="openDateMenu"
      :close-on-content-click="false"
      :activator="openDateElement"
      :nudge-top="-5"
      offset-y
      left
    >
      <v-card
        color="grey lighten-4"
        min-width="250px"
        flat
      >
        <v-card-title class="blue white--text justify-space-between py-2 body-2">
          <span class="caption font-weight-bold pr-8">
            {{ $localDT(openDateEvent.date, 'sajian') }}
          </span>
          <v-chip x-small class="text-capitalize">
            {{ openDateEvent.type }}
          </v-chip>
        </v-card-title>
        <v-card-text class="py-4">
          <v-autocomplete
            v-model="productSelected"
            :loading="loadingProduct"
            :items="products"
            :search-input.sync="search_products"
            item-text="product_name"
            flat
            hide-no-data
            hide-details
            return-object
            label="Pilih Menu"
            solo-inverted
            class="mb-4"
          />
          <v-text-field
            v-model="newSchedule.schedule_notes"
            hide-details
            flat
            solo-inverted
            label="Catatan (e.g kirim jam 7 s/d 9)"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-4">
          <v-btn small class="text-capitalize" color="red" dark text @click.prevent="openDateMenu = false">
            Close
          </v-btn>
          <v-spacer />
          <v-btn small class="text-capitalize" color="blue" dark depressed @click.prevent="SCHEDULE_ADD()">
            Process
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
let searchTimeout = null
const schedule = {
  id: null,
  schedule_date: '',
  schedule_type: '',
  schedule_notes: '',
  store: 0,
  product: 0,
  status: 1
}
export default {
  name: 'Schedules',
  data: vm => ({
    schedules: {},
    filterMenu: false,
    openDateMenu: false,
    openDateEvent: {},
    openDateElement: null,
    focus: vm.$localDT(vm.$thisMonth(new Date()).first, 'datedefault').substr(0, 7),
    search_products: '',
    loadingProduct: false,
    products: [],
    productSelected: null,
    renderComponent: true,
    newSchedule: Object.assign({}, schedule)
  }),
  computed: {
    ...mapState({
      storeActive: state => state.storeActive
    })
  },
  watch: {
    storeActive () {
      this.LoadSchedules()
    },
    focus () {
      this.LoadSchedules()
    },
    search_products (v) {
      if (!v) {
        return false
      }
      if (this.productSelected) {
        this.newSchedule.product = parseInt(this.productSelected.product)
        if (this.productSelected.product_name === v) {
          return false
        }
      }
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.PRODUCT_GET()
      }, 300)
    }
  },
  mounted () {
    this.LoadSchedules()
  },
  methods: {
    SCHEDULE_ADD () {
      this.newSchedule.id = null
      if (!parseInt(this.newSchedule.store)) {
        this.$store.dispatch('TOAST', { show: true, message: 'No branch selected!' })
        return false
      } else if (!parseInt(this.newSchedule.store)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid product!' })
        return false
      } else if (!this.newSchedule.schedule_type) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid type schedule!' })
        return false
      } else if (!this.newSchedule.schedule_date) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid date schedule!' })
        return false
      }
      this.$store.dispatch('SCHEDULES_ADD', this.newSchedule)
        .then((res) => {
          if (res.status) {
            const d = res.data.data || {}
            if (parseInt(d.id)) {
              this.newSchedule = Object.assign({}, schedule)
              if (!this.schedules[d.schedule_date]) {
                this.schedules[d.schedule_date] = {}
              }
              d.product_name = this.productSelected.product_name
              d.galleries = this.productSelected.galleries
              this.schedules[d.schedule_date][d.schedule_type] = Object.assign({}, d)
              this.openDateMenu = false
              this.$store.dispatch('TOAST', { show: true, message: 'Success!' })
              this.forceRerender()
            } else {
              this.$store.dispatch('TOAST', { show: true, message: 'Failed!' })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
          }
        })
    },
    ParseSchedule (date, type) {
      if (this.schedules[date]) {
        return this.schedules[date][type] || {}
      }
      return {}
    },
    NavCalendar (e) {
      const curr = new Date(this.$localDT(this.focus + '-01', 'datedefault'))
      curr.setDate(1)
      if (e === 'next') {
        curr.setMonth(curr.getMonth() + 1)
        this.focus = this.$localDT(curr, 'datedefault').substr(0, 7)
      } else {
        curr.setMonth(curr.getMonth() - 1)
        this.focus = this.$localDT(curr, 'datedefault').substr(0, 7)
      }
    },
    LoadSchedules () {
      this.renderComponent = false
      this.schedules = {}
      const d = this.$thisMonth(this.focus + '-01')
      const firstDay = this.$localDT(this.$getThisWeek(d.first, null, true).from, 'datedefault')
      const lastDay = this.$localDT(this.$getThisWeek(d.last, null, true).to, 'datedefault')
      this.$store.dispatch('SCHEDULES_GET', '?store=' + this.storeActive + '&from=' + firstDay + '&to=' + lastDay)
        .then((res) => {
          if (res.status) {
            const schedules = this.$ArrToObj(res.data.data, 'schedule_date') || {}
            const d = Object.keys(schedules)
            for (let s = 0; s < d.length; s++) {
              const x = this.$ArrToObj(schedules[d[s]], 'schedule_type') || {}
              const y = Object.keys(x)
              this.schedules[d[s]] = {}
              for (let z = 0; z < y.length; z++) {
                this.schedules[d[s]][y[z]] = Object.assign({}, x[y[z]][0])
              }
            }
            this.forceRerender()
          }
        })
    },
    showEvent (nativeEvent, event) {
      const open = () => {
        this.openDateEvent = event
        this.openDateElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => {
          this.openDateMenu = true
        }))
      }
      if (this.openDateMenu) {
        this.openDateMenu = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        if (parseInt(event.data.id)) {
          if (!confirm('Remove schedule ?')) {
            return false
          }

          this.$store.dispatch('SCHEDULES_DELETE', event.data.id)
            .then((res) => {
              delete this.schedules[event.data.schedule_date][event.data.schedule_type]
              this.forceRerender()
            })
        } else {
          this.search_products = ''
          this.products = []
          this.productSelected = null
          this.newSchedule.id = null
          this.newSchedule.schedule_date = event.date
          this.newSchedule.schedule_type = event.type
          this.newSchedule.store = parseInt(this.$store.state.storeActive) || 0
          this.newSchedule.schedule_notes = ''
          open()
        }
      }

      nativeEvent.stopPropagation()
    },
    PRODUCT_GET () {
      this.products = []
      this.loadingProduct = true
      this.productSelected = null
      this.$store.dispatch('product/PRODUCT_GET', '?status=1&limit=12&q=' + (this.search_products || '')).then((res) => {
        this.loadingProduct = false
        if (res.status) {
          this.products = res.data.data.data
        } else {
          this.products = []
        }
      })
    },
    updateRange ({ start, end }) {
      // console.log('range: ', start, end)
    },
    forceRerender () {
      this.renderComponent = false
      this.$nextTick().then(() => {
        this.renderComponent = true
      })
    }
  }
}
</script>

<style lang="scss">
.v-calendar-weekly__head {
  height: 45px!important;
  >.v-calendar-weekly__head-weekday {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    width: 100%;
  }
}
.v-calendar-weekly__day {
  >.v-calendar-weekly__day-label {
    background: #ff5c00!important;
    padding: 5px 10px;
    button {
      width: 100%!important;
      >span,
      >.v-btn__content {
        color: #ff5c00!important;
        padding: 4px 10px!important;
        width: 100%;
        background: #fff!important;
        border-radius: 4px;
      }
    }
  }
  &.v-present {
    >.v-calendar-weekly__day-label {
      button {
        >span,
        >.v-btn__content {
          color: #fff!important;
          background: #ff5c00!important;
          font-weight: 800;
        }
      }
    }
  }
}
</style>
